import { Typography } from '@mui/material';
import coverImg from '../../images/cover.jpg';
import { styled } from '@mui/system';

const MainContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  background: `url(${coverImg}) center`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundAttachment: 'unset',
  },
}));

const StyledTypography = styled(Typography)({
  marginBottom: '0.6em',
  maxWidth: '17em',
  textAlign: 'center',
  lineHeight: '1.25em'
});

const Landing = () => {
  return (
    <MainContainer>
      <Typography
        variant='h3'
        color='primary'
        fontWeight='bold'
        mt={14}
        mb={2}
        textAlign='center'
      >
        Empowering to Heal and Grow
      </Typography>
      <StyledTypography fontSize='1.4em'>
        Shame dies when stories are shared in a safe place.
      </StyledTypography>
      <StyledTypography fontSize='1.4em'>
        This is your safe space to claim inner peace and harmony.
      </StyledTypography>
      {/* ToDo: Remove?? */}
      <StyledTypography fontSize='1.4em'>
        Help is just a Click away!
      </StyledTypography>
    </MainContainer>
  );
};

export default Landing;

// 0d4659