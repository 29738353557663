import { Link } from 'react-router-dom';
import SigninForm from '../Components/SigninForm';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  background: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Signup = () => {
  return (
    <MainContainer>
      <SigninForm isSignupPage={true} />
      <Typography fontSize='1.1em' color={'#FFF0D7'} mt={2}>
        Already have an account?
        <Link
          to='../login'
          style={{
            color: '#FFF0D7',
            fontWeight: 'bold',
            marginLeft: '0.25em',
            textDecoration: 'none',
          }}
        >
          Login here
        </Link>
      </Typography>
    </MainContainer>
  );
};

export default Signup;
