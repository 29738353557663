import { Link, NavLink, Outlet } from 'react-router-dom';
import { useRef, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import Footer from '../Components/Footer';
import LogoImg from '../images/logo.jpg';
import MenuMobile from '../Components/MenuMobile';
import { Person } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';

const classes = {
  logo_text: {
    color: '#E4572E',
    marginLeft: '0.5em',
    textDecoration: 'none',
  },
  active_nav: {
    color: '#E4572E',
    fontWeight: '600',
  },
  menu_open: {
    display: 'flex',
  },
};

const AppBar = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const NavbarContainer = styled('nav')(({ theme }) => ({
  width: '90vw',
  background: theme.palette.tertiary.background,
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1.5em',
  padding: '0.2em',
  marginTop: '0.75em',
  position: 'fixed',
  borderRadius: '40px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: 0,
  },
}));

const LogoContainer = styled('div')(({ theme }) => ({
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    paddingTop: '0.4em',
    justifyContent: 'space-between',
  },
}));
const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const LogoImage = styled('img')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '2.5em',
  margin: '0 0.5em',
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '2.2em',
    margin: '0 0.5em 0 0.1em',
  },
}));

const NavLinks = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.menu_close': {
      display: 'none',
    },
    '.menu_open': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const NavButtonLinks = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.background,
  textDecoration: 'none',
  paddingLeft: '1em',
  paddingRight: '0.3em',
  [theme.breakpoints.down('sm')]: {
    padding: '0.2em',
    '.menu_close': {
      display: 'none',
    },
    '.menu_open': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const Layout = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const reviewsRef = useRef(null);
  
  const userId = useSelector((state) => state.auth.userId);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const toggleMenu = (value) => {
    setIsMenuOpen(value);
  };
  const closeMenu = (section) => {
    setIsMenuOpen(false);
    setActiveSection(section);
  };

  const isMobile = window.innerWidth <= 500;
  const scrollToRef = (ref, section, offset = 0) => {
    closeMenu(section);
    setTimeout(() => {
      if (ref && ref.current) {
        const topPosition =
          ref.current.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
    }, 10);
  };

  return (
    <>
      <AppBar>
        <NavbarContainer>
          <LogoContainer>
            <Link
              to=''
              onClick={() => scrollToRef(homeRef, 'home')}
              style={classes.logo_text}
            >
              <Logo>
                <LogoImage src={LogoImg} />
                Manosamvaad
              </Logo>
            </Link>
            <div style={{ marginRight: '0.3em' }}>
              <MenuMobile isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </div>
          </LogoContainer>
          <>
            <NavLinks>
              <StyledNavLink
                to=''
                onClick={() =>
                  scrollToRef(aboutRef, 'about', isMobile ? -115 : -85)
                }
                className={isMenuOpen ? 'menu_open' : 'menu_close'}
                style={
                  activeSection === 'about' ? classes.active_nav : undefined
                }
              >
                About
              </StyledNavLink>
              <StyledNavLink
                to=''
                onClick={() =>
                  scrollToRef(servicesRef, 'services', isMobile ? -90 : -70)
                }
                className={isMenuOpen ? 'menu_open' : 'menu_close'}
                style={
                  activeSection === 'services' ? classes.active_nav : undefined
                }
              >
                Services
              </StyledNavLink>
              <StyledNavLink
                to=''
                onClick={() => scrollToRef(reviewsRef, 'reviews')}
                className={isMenuOpen ? 'menu_open' : 'menu_close'}
                style={
                  activeSection === 'reviews' ? classes.active_nav : undefined
                }
              >
                Reviews
              </StyledNavLink>
              <StyledNavLink
                to='team'
                onClick={() => closeMenu('')}
                className={isMenuOpen ? 'menu_open' : 'menu_close'}
                style={({ isActive }) =>
                  isActive ? classes.active_nav : undefined
                }
              >
                Our Team
              </StyledNavLink>
            </NavLinks>
            <NavButtonLinks>
              <StyledNavLink
                to='contact'
                onClick={() => closeMenu('')}
                style={({ isActive }) =>
                  isActive ? classes.active_nav : undefined
                }
              >
                <Button
                  className={isMenuOpen ? 'menu_open' : 'menu_close'}
                  variant='contained'
                  color='primary'
                  style={{
                    fontSize: '0.9em',
                    borderRadius: '40px',
                    textTransform: 'none',
                  }}
                >
                  Book Session
                </Button>
              </StyledNavLink>
              {userId ? (
                <StyledNavLink
                  to={`profile/${userId}`}
                  onClick={() => closeMenu('')}
                >
                  <Avatar
                    className={isMenuOpen ? 'menu_open' : 'menu_close'}
                    sx={{
                      width: 45,
                      height: 45,
                      boxShadow: '1px 2px 2px 0px rgba(0, 0, 0, 0.25)',
                      background: '#E4572E',
                      '&:hover': {
                        background: '#D23C12',
                      },
                    }}
                  >
                    <Person />
                  </Avatar>
                </StyledNavLink>
              ) : (
                <StyledNavLink to='login'>
                  <Button
                    className={isMenuOpen ? 'menu_open' : 'menu_close'}
                    variant='contained'
                    color='primary'
                    style={{
                      fontSize: '0.9em',
                      borderRadius: '40px',
                      textTransform: 'none',
                    }}
                  >
                    Login
                  </Button>
                </StyledNavLink>
              )}
            </NavButtonLinks>
          </>
        </NavbarContainer>
      </AppBar>
      <Outlet context={{ homeRef, aboutRef, servicesRef, reviewsRef }} />
      <Footer />
    </>
  );
};

export default Layout;
