export const SERVICES_URL = '/common/services';
export const REVIEWS_URL = '/common/reviews';
export const CONTACT_URL = '/common/contact';
export const SIGNUP_URL = '/authenticate/signup';
export const LOGIN_URL = '/authenticate/login';
export const USER_URL = '/users';
export const FOOTER_URLS = {
  MAP_URL: 'https://maps.app.goo.gl/wiMaUTpPMMD48SrW6',
  EMAIL: 'manosamvaad@gmail.com',
  PHONE_URL: '+919717684788',
  WHATSAPP_URL: 'https://wa.me/919717684788',
  INSTAGRAM_URL: 'https://www.instagram.com/manosamvaad/',
  FACEBOOK_URL: 'https://www.facebook.com/manosamvaad/',
  YOUTUBE_URL: 'https://www.youtube.com/@Manosamvaad',
};
