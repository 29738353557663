import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Email, Female, Lock, Male, Person } from '@mui/icons-material';
import { login, signup } from '../utils/apiFunctions';
import { loginUser, logoutUser } from '../store/authSlice';

import { setNotification } from '../store/notificationSlice';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const MainContainer = styled('div')(({ theme }) => ({
  background: '#FFF0D7',
  padding: '1.5em',
  marginTop: '5em',
  borderRadius: '10px',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '25em',
  // background: '#FFF',
  // marginTop: '1em',
  // padding: '1em 1em 2em 1em',
  [theme.breakpoints.down('sm')]: {
    width: '20em',
  },
}));

const VerticallyCenter = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const SigninTypography = styled(Typography)({
  fontSize: '1.25em',
  fontWeight: 'bold',
  textAlign: 'center',
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '0.7em',
  margin: '0.75em',
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.tertiary.main, // Default border color
  },
  '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.main, // Border color on hover
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.tertiary.main, // Default label color
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: '1em',
  letterSpacing: '0.02em',
  paddingLeft: '1.78em',
  paddingRight: '1.78em',
  marginTop: '0.5em',
  marginBottom: '0.5em',
  textTransform: 'capitalize',
  borderColor: 'black',
  '&.Mui-selected': {
    background: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.95em',
    paddingRight: '0.95em',
  },
}));

const SigninForm = ({ isLoginPage, isSignupPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [inputErrors, setInputErrors] = useState({});

  const validateInputs = () => {
    const errors = {};
    if (isSignupPage && formData.firstName.length === 0) {
      errors.firstName = 'Name is required!';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      errors.email = 'Email is invalid!';
    }
    if (formData.password.length < 6) {
      errors.password = 'Password must have at least 6 characters!';
    }
    if (isSignupPage && formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match!';
    }
    setInputErrors(errors);
    return !(Object.keys(errors).length > 0);
  };

  const handleChange = (event, newVal) => {
    const { name, value } = event.target;
    // validateInputs();
    if (name === 'gender') {
      setFormData({ ...formData, gender: newVal });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateInputs()) {
      try {
        const res = isSignupPage
          ? await signup(formData)
          : await login(formData);

        // login: 200, signup: 201
        if (res.status === 200 || res.status === 201) {
          dispatch(
            setNotification({ message: res.data.message, type: 'success' })
          );
        }
        if (res.status === 200) {
          dispatch(
            loginUser({
              authToken: res.data.authToken,
              userId: res.data.userId,
            })
          );
          navigate(`/profile/${res.data.userId}`);
        }
      } catch (err) {
        dispatch(
          setNotification({
            message: err.response.data.error,
            type: 'error',
          })
        );
      }
    }
  };

  return (
    <MainContainer>
      <SigninTypography color='secondary' mb={1}>
        {isSignupPage && 'Create Account'}
        {isLoginPage && 'Login'}
      </SigninTypography>
      <FormContainer component='form' onSubmit={handleSubmit}>
        {isSignupPage && (
          <VerticallyCenter>
            <Person />
            <StyledTextField
              label='First Name'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              error={!!inputErrors.firstName}
              helperText={inputErrors.firstName}
              required
            />
            <StyledTextField
              label='Last Name'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              error={!!inputErrors.lastName}
              helperText={inputErrors.lastName}
            />
          </VerticallyCenter>
        )}
        {isSignupPage && (
          <VerticallyCenter>
            <Male sx={{ fontSize: '1.7em', marginLeft: '-0.45em' }} />
            <Female sx={{ fontSize: '1.7em', marginLeft: '-0.35em' }} />
            <FormControl component='fieldset'>
              <ToggleButtonGroup
                value={formData.gender}
                exclusive
                onChange={handleChange}
                aria-label='gender'
                name='gender'
              >
                <StyledToggleButton
                  name='gender'
                  value='male'
                  aria-label='male'
                >
                  Male
                </StyledToggleButton>
                <StyledToggleButton
                  name='gender'
                  value='female'
                  aria-label='female'
                >
                  Female
                </StyledToggleButton>
                <StyledToggleButton
                  name='gender'
                  value='transgender'
                  aria-label='transgender'
                >
                  Transgender
                </StyledToggleButton>
              </ToggleButtonGroup>
              {inputErrors.gender && (
                <FormHelperText error>{inputErrors.gender}</FormHelperText>
              )}
            </FormControl>
          </VerticallyCenter>
        )}
        <VerticallyCenter>
          <Email />
          <StyledTextField
            size='20'
            fullWidth
            label='Enter Email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            error={!!inputErrors.email}
            helperText={inputErrors.email}
            required
          />
        </VerticallyCenter>
        <VerticallyCenter>
          <Lock />
          <StyledTextField
            fullWidth
            label={
              isSignupPage
                ? 'Create Password'
                : isLoginPage
                ? 'Enter Password'
                : 'Password'
            }
            name='password'
            value={formData.password}
            onChange={handleChange}
            error={!!inputErrors.password}
            helperText={inputErrors.password}
            type='password'
            required
          />
        </VerticallyCenter>
        {isSignupPage && (
          <VerticallyCenter>
            <Lock />
            <StyledTextField
              fullWidth
              label='Retype Password'
              name='confirmPassword'
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!inputErrors.confirmPassword}
              helperText={inputErrors.confirmPassword}
              type='password'
              required
            />
          </VerticallyCenter>
        )}
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='secondary'
          sx={{
            color: '#FFF0D7',
            mt: 2,
            fontSize: '0.9em',
            letterSpacing: '0.05em',
          }}
        >
          {isSignupPage && 'Signup'}
          {isLoginPage && 'Login'}
        </Button>
      </FormContainer>
    </MainContainer>
  );
};
export default SigninForm;
