import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Contact from './pages/Contact';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Login from './pages/Login';
import NavHeader from './pages/NavHeader';
import NoPage from './pages/NoPage';
import Notification from './Components/Notification';
import Profile from './pages/Profile';
import Signup from './pages/Signup';
import Team from './pages/Team';
import ThemeWrapper from './ThemeWrapper';
import autoLogout from './HOC/autoLogout';

function App() {
  const WrappedLayout = autoLogout(Layout);
  const WrappedNavHeader = autoLogout(NavHeader);
  return (
    <ThemeWrapper>
      <BrowserRouter>
        <Notification />
        <Routes>
          <Route path='/' element={<WrappedLayout />}>
            <Route index element={<Home />} />
            <Route path='team' exact element={<Team />} />
            <Route path='contact' exact element={<Contact />} />
            <Route path='profile/:userId' exact element={<Profile />} />
          </Route>
          <Route path='/' element={<WrappedNavHeader />}>
            <Route path='signup' element={<Signup />} />
            <Route path='login' element={<Login />} />
            <Route path='*' element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeWrapper>
  );
}

export default App;
