import { Typography } from '@mui/material';
import defaultImage from '../images/defaultProfileImg.png';
import { styled } from '@mui/system';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5em 2em',
}));

const Image = styled('img')(({ theme }) => ({
  width: '15em',
  height: '15em',
  borderRadius: '50%',
  marginBottom: '1em',
  objectFit: 'cover',
  objectPosition: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.15em',
  textAlign: 'center',
}));

const Avatar = ({ data }) => {
  return (
    <MainContainer>
      <Image
        src={data.image}
        alt='profile-img'
      />
      <StyledTypography fontWeight='bold'>
        {data.firstName} {data.lastName}
      </StyledTypography>
      <StyledTypography>{data.designation}</StyledTypography>
      {/* <StyledTypography>
        <span>Specializes in </span>
        {data.specialization}
      </StyledTypography> */}
    </MainContainer>
  );
};

export default Avatar;
