import { useEffect, useState } from 'react';

import Avatar from '../Components/Avatar';
import CircularButton from '../Components/CircularButton';
import { Typography } from '@mui/material';
import { fetchUsers } from '../utils/apiFunctions';
import { styled } from '@mui/system';

const Background = styled('div')(({ theme }) => ({
  height: '89.7vh',
  background: theme.palette.primary.background,
  [theme.breakpoints.down('sm')]: {
    height: '85.09vh',
  },
}));

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '7em',
}));

const AvatarsContainer = styled('div')({
  display: 'flex',
});
const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '8em',
  position: 'absolute',
  bottom: '6em',
  [theme.breakpoints.down('sm')]: {
    bottom: '5em',
  },
}));

const Team = () => {
  const [therapists, setTherapists] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(false);
  
  useEffect(() => {
    // 1 on mobile, 4 on laptop
    if (window.innerWidth <= 500)
      setItemsPerPage(1)
    else setItemsPerPage(4)
  }, [])
  

  useEffect(() => {
    const loadTeamData = async () => {
      try {
        const fetchRole = 'therapist'; // fetches all therapists
        const therapists = await fetchUsers({ fetchRole });
        setTherapists(therapists);
      } catch (err) {
        console.error(err);
      }
    };
    loadTeamData();
  }, []);

  const showPrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0));
  };

  const showNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + itemsPerPage, therapists?.length - itemsPerPage)
    );
  };

  const visibleData = therapists?.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  return (
    <Background>
      <MainContainer>
        <Typography variant='h5' color='primary' fontWeight='bold'>
          Meet Our Therapists
        </Typography>
        <Typography fontSize='1.15em' margin='0.5em 1.5em 0 1.5em'>
          Our experienced therapists specialize in their domain and help people
          to heal and grow.
        </Typography>
        <AvatarsContainer>
          {visibleData?.map((member) => {
            return <Avatar data={member} />;
          })}
        </AvatarsContainer>
        <ButtonsContainer>
          <CircularButton
            direction='left'
            onClickHandler={showPrevious}
            disabled={currentIndex === 0}
          />
          <CircularButton
            onClickHandler={showNext}
            disabled={currentIndex + itemsPerPage >= therapists?.length}
          />
        </ButtonsContainer>
      </MainContainer>
    </Background>
  );
};

export default Team;
