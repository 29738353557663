import About from './Screens/About';
import Landing from './Screens/Landing';
import Reviews from './Screens/Reviews';
import Services from './Screens/Services';
import { fetchServices } from '../utils/apiFunctions';
import { setServices } from '../store/servicesSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const { homeRef, aboutRef, servicesRef, reviewsRef } = useOutletContext();

  useEffect(() => {
    const loadServices = async () => {
      try {
        const services = await fetchServices();
        dispatch(setServices(services));
      } catch (err) {
        console.error(err);
      }
    };
    loadServices();
  }, []);

  return (
    <>
      <div ref={homeRef}>
        <Landing />
      </div>
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={servicesRef}>
        <Services />
      </div>
      <div ref={reviewsRef}>
        <Reviews />
      </div>
    </>
  );
};

export default Home;
