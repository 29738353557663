import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { clearNotification } from '../store/notificationSlice';
import { styled } from '@mui/system';

const getColor = (type) => {
  switch (type) {
    case 'success':
      return {
        color: '#FFF',
        background: '#25AA58',
      };
    case 'error':
      return {
        color: '#FFF',
        background: '#CA1313',
      };
    default:
      return {
        color: '#FFF',
        background: '#6A6262',
      };
  }
};

const NotificationContainer = styled('div')(({ type, isVisible }) => ({
  ...getColor(type),
  padding: '0.75em 1em',
  position: 'fixed',
  left: '50%',
  transform: isVisible
    ? 'translateX(-50%) translateY(95px)'
    : 'translateX(-50%) translateY(27px)',
  borderRadius: '5px',
  transition: 'transform 1.2s ease-out',
}));

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (notification.message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        dispatch(clearNotification());
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [notification, dispatch]);

  if (!notification.message) return null;
  return (
    <NotificationContainer type={notification.type} isVisible={isVisible}>
      {notification.message}
    </NotificationContainer>
  );
};

export default Notification;
