import { useEffect, useState } from 'react';

import InfScroll from '../../Components/InfScroll';
import { Rating } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import { Typography } from '@mui/material';
import { fetchReviews } from '../../utils/apiFunctions';
import { styled } from '@mui/system';

const MainContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.background,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const InfoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2em',
  fontWeight: 'bold',
});

const TypographyBody = styled('div')(({ theme }) => ({
  color: theme.palette.tertiary.main,
  padding: '0.5em',
  maxHeight: '8em',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '10em',
  },
}));
const TypographyName = styled('div')(({ theme }) => ({
  color: theme.palette.tertiary.main,
  padding: '0.5em',
}));

const ReviewBox = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: '#FFF0D7',
  boxShadow: '0 0 16px rgba(0, 0, 0, 0.2)',
  width: '92%',
  fontSize: '1.1em',
  textAlign: 'center',
  padding: '1em',
}));

const Services = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        const reviews = await fetchReviews();
        setReviews(reviews);
      } catch (err) {
        console.error(err);
      }
    };
    loadReviews();
  }, []);

  const renderReviews = (item) => {
    return (
      <ReviewBox>
        <Rating name='read-only' value={item.stars} p={1} readOnly />
        <TypographyBody>
          {item.description}
        </TypographyBody>
        <TypographyName>{item.name}</TypographyName>
      </ReviewBox>
    );
  };
  
  return (
    <MainContainer>
      <Typography fontSize='1.3em' margin='1.2em'>
        What our Clients say
      </Typography>
      <InfoContainer>
        <TypeAnimation
          sequence={[
            '15,000+ Satisfied Customers',
            1000,
            // '100+ Google Reviews',
            '4.9⭐ rated on Google Reviews',
            1000,
          ]}
          wrapper='div'
          repeat={Infinity}
          speed={200}
          deletionSpeed={30}
          delay={200}
        ></TypeAnimation>
      </InfoContainer>
      <div style={{ margin: '0.75em' }}>
        <InfScroll
          items={reviews}
          itemsOnPage={2}
          time={8}
          direction={'row'}
          renderItem={renderReviews}
        />
      </div>
    </MainContainer>
  );
};

export default Services;
