import { Link, Outlet } from 'react-router-dom';

import LogoImg from '../images/logo.jpg';
import { styled } from '@mui/system';

const classes = {
  logo_text: {
    color: '#E4572E',
    marginLeft: '0.5em',
    textDecoration: 'none',
  },
};

const AppBar = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const NavbarContainer = styled('nav')(({ theme }) => ({
  width: '13em',
  background: theme.palette.tertiary.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.3em',
  padding: '0.3em',
  marginTop: '0.75em',
  position: 'fixed',
  borderRadius: '40px',
  fontWeight: '700',
}));

const LogoImage = styled('img')({
  display: 'flex',
  alignItems: 'center',
  width: '2.5em',
  borderRadius: '10px',
});

const NavHeader = () => {
  return (
    <>
      <AppBar>
        <NavbarContainer>
          <Link to=''>
            <LogoImage src={LogoImg} />
          </Link>
          <Link to='' style={classes.logo_text}>
            Manosamvaad
          </Link>
        </NavbarContainer>
      </AppBar>
      <Outlet />
    </>
  );
};

export default NavHeader;
