import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import adult from '../../images/services/adult.png';
import couple from '../../images/services/couple.png';
import growth from '../../images/services/growth.jpg';
import hypnotherapy from '../../images/services/hypnotherapy.jpg';
import { styled } from '@mui/system';
import teens from '../../images/services/teens.jpg';
import { useSelector } from 'react-redux';
import workshop from '../../images/services/workshop.jpg';

const MainContainer = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.background,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '1.5em',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));

const StyledAccordionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  background: '#FEBB48',
  color: '#FFF',
}));

const StyledImage = styled('img')(({ theme }) => ({
  // height: '15.64em', not needed
  width: '27.8em',
  objectFit: 'cover',
  marginBottom: '-0.23em',
  [theme.breakpoints.down('sm')]: {
    width: '20.7em', // exact
  },
}));

const ServicesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)', // 2 services per row
  gap: 2,
  marginTop: '-0.5em',
}));

const ServiceText = styled(Box)({ display: 'flex', alignItems: 'center' });

const TypographyTitle = styled('div')(({ theme }) => ({
  fontSize: '1.1em',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: 0.3,
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: '1.4em',
  marginLeft: '1.1em',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}));

const TypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: '1.1em',
  color: theme.palette.tertiary.main,
  width: '57vw',
  margin: '0.5em 0 1em 1.5em',
  textAlign: 'justify',
  [theme.breakpoints.down('sm')]: {
    width: '85vw',
    margin: '0.25em 0 0.75em 0',
  },
}));


const images = {
  'teens': teens,
  'couple': couple,
  'hypnotherapy': hypnotherapy,
  'adult': adult,
  'growth': growth,
  'workshop': workshop,
};

const Services = () => {
  const services = useSelector((state) => state.services.services);
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <MainContainer>
      <TypographyHeading>
        How can we help you?
      </TypographyHeading>
      <TypographyBody>
        At Manosamvaad, we are dedicated to providing compassionate and
        comprehensive mental health services to support your journey towards
        emotional well-being and resilience. Our team of experienced
        professionals offers a wide range of services designed to address the
        unique challenges you may face.
      </TypographyBody>
      <Grid container spacing={2.5}>
        {services.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Accordion
              expanded={expanded === index}
              onChange={handleExpand(index)}
            >
              <AccordionSummary>
                <Box>
                  <StyledImage src={images[item.image]} alt={item.title} />
                  <StyledAccordionTitle>
                    <TypographyTitle>{item.title}</TypographyTitle>
                    {expanded === index ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </StyledAccordionTitle>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ServicesGrid>
                  <Box>
                    {item.services
                      .slice(0, Math.ceil(item.services.length / 2))
                      .map((service, i) => (
                        <ServiceText key={i}>
                          <AcUnitIcon
                            fontSize='1em'
                            sx={{ marginRight: 0.5 }}
                          />
                          <Typography>{service}</Typography>
                        </ServiceText>
                      ))}
                  </Box>
                  <Box>
                    {item.services
                      .slice(Math.ceil(item.services.length / 2))
                      .map((service, i) => (
                        <ServiceText key={i}>
                          <AcUnitIcon
                            fontSize='1em'
                            sx={{ marginRight: 0.5 }}
                          />
                          <Typography>{service}</Typography>
                        </ServiceText>
                      ))}
                  </Box>
                </ServicesGrid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </MainContainer>
  );
};

export default Services;
