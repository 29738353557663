import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: { 'Content-Type': 'application/json' },
});

export const getRequest = async (url, headers) => {
  try {
    const res = await api.get(url, { headers });
    return res;
  } catch (err) {
    console.error('Error fetching data:', err);
    throw err;
  }
};

export const postRequest = async (url, data, headers) => {
  try {
    const resp = await api.post(url, data, { headers });
    return resp;
  } catch (err) {
    console.error('Error posting data:', err);
    throw err;
  }
};
