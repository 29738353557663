import {
  CONTACT_URL,
  LOGIN_URL,
  REVIEWS_URL,
  SERVICES_URL,
  SIGNUP_URL,
  USER_URL,
} from '../constants/constants';
import { getRequest, postRequest } from './apiHelpers';

export const fetchServices = async () => {
  try {
    const res = await getRequest(SERVICES_URL);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchReviews = async () => {
  try {
    const res = await getRequest(REVIEWS_URL);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const submitForm = async (data) => {
  try {
    const res = await postRequest(CONTACT_URL, data);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signup = async (data) => {
  const { firstName, lastName, gender, email, password } = data;
  try {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      email: email,
      password: password,
    };
    const res = await postRequest(SIGNUP_URL, payload);
    return res;
  } catch (err) {
    console.error('Error in signup', err);
    throw err;
  }
};

export const login = async (data) => {
  const { email, password } = data;
  try {
    const payload = {
      email: email,
      password: password,
    };
    const res = await postRequest(LOGIN_URL, payload);
    return res;
  } catch (err) {
    console.error('Error in login', err);
    throw err;
  }
};

export const fetchUserData = async (data) => {
  const { authToken, userId } = data;
  try {
    const url = `${USER_URL}/${userId}`;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const res = await getRequest(url, headers);
    return res.data;
  } catch (err) {
    console.error('Error fetching user data', err);
    throw err;
  }
};

export const fetchUsers = async (data) => {
  const { fetchRole } = data;
  try {
    const url = `${USER_URL}`;
    const params = {
      role: fetchRole,
    };
    const res = await postRequest(url, params);
    return res.data;
  } catch (err) {
    console.error('Error fetching users', err);
    throw err;
  }
};

export const fetchUserSessions = async (data) => {
  const { authToken, email, role } = data;
  try {
    const url = `${USER_URL}/sessions`;
    const params = {
      email: email,
      role: role,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const res = await postRequest(url, params, headers);
    return res.data;
  } catch (err) {
    console.error('Error fetching sessions of user', err);
    throw err;
  }
};

export const updateSession = async (data) => {
  const { authToken, sessionId, attribute } = data;
  try {
    const url = `${USER_URL}/update-session`;
    const params = {
      sessionId: sessionId,
      attribute: attribute,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const res = await postRequest(url, params, headers);
    return res.data;
  } catch (err) {
    console.error('Error updating Session', err);
    throw err;
  }
};

export const uploadProfileImage = async (data) => {
  const { authToken, formData } = data;
  try {
    const url = `${USER_URL}/upload-image`
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };
    const res = await postRequest(url, formData, headers);
    return res.data;
  } catch (err) {
    console.error('Error uploading profile picture')
    throw err;
  }
}

export const sendEmail = async (data) => {
  const { authToken, sessionData } = data;
  try {
    const url = `${USER_URL}/email`;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const res = await postRequest(url, sessionData, headers);
    return res.data;
  } catch (err) {
    console.error('Error updating Session', err);
    throw err;
  }
};