import InfScroll from '../../Components/InfScroll';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const images = [
  require('../../images/about/about1.jpg'),
  require('../../images/about/about2.jpg'),
  require('../../images/about/about3.jpg'),
  require('../../images/about/about4.jpg'),
  require('../../images/about/about5.jpg'),
  require('../../images/about/about6.jpg'),
  require('../../images/about/about7.jpg'),
  require('../../images/about/about8.jpg'),
  require('../../images/about/about9.jpg'),
];

const MainContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.background,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '1em 1.5em 1em 3em',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  width: '57vw',
  [theme.breakpoints.down('sm')]: {
    width: '85vw',
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  height: '85vh',
  display: 'flex',
  flexWrap: 'wrap',
  // marginTop: '-1em',
  [theme.breakpoints.down('sm')]: {
    height: '31vh',
    marginTop: '-0.5em',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '30em',
  [theme.breakpoints.down('sm')]: {
    width: '22em',
  },
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2em',
  fontWeight: 'bold',
  marginTop: '0.5em',
  textAlign: 'center',
}));

const TypographyBody = styled(Typography)(({ theme }) => ({
  color: theme.palette.tertiary.main,
  fontSize: '1.1em',
  textAlign: 'justify',
}));

const About = () => {
  const renderImages = (image) => {
    return <StyledImage src={image} alt={image} key={image} />;
  };

  return (
    <MainContainer>
      <TextContainer>
        <Typography
          fontSize='1.4em'
          fontWeight='bold'
          textAlign='center'
        >
          About Us
        </Typography>
        <TypographyBody>
          At Manosamvaad, we believe that mental health is just as vital as physical health, and everyone deserves access to compassionate, high-quality care. With a dedicated team of professionals from diverse backgrounds, we have become a trusted provider of mental health services, empowering over 15,000 individuals to take charge of their mental well-being and live more fulfilling lives.
        </TypographyBody>
        <TypographyBody mt={1}>
          We understand that each person’s journey is unique, which is why we offer personalized sessions tailored to the specific needs of every individual. Whether you're seeking support for anxiety, depression, trauma, or any other mental health concern, you will find a compassionate partner in us. We aim to empower our clients with the tools and skills they need to build resilience, improve their quality of life, and achieve their personal goals.
        </TypographyBody>
        <TypographyTitle>Mission</TypographyTitle>
        <TypographyBody>
          Our mission is to provide a safe and supportive space where individuals can find healing, hope, and the strength to thrive on their journey toward mental wellness.
        </TypographyBody>
        <TypographyTitle>Values</TypographyTitle>
        <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Empathy: </span>We honor each person's unique experiences and emotions, ensuring that you always feel seen, heard, and valued.
        </TypographyBody>
        <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Accessibility: </span>Our services are designed to be inclusive, approachable, and available to everyone, regardless of background or circumstance.
        </TypographyBody>
        <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Integrity: </span>We adhere to the highest standards of ethics and transparency, building trust through honesty and accountability.
        </TypographyBody>
        {/* <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Empowerment: </span>We encourage and support you in taking control of your mental health journey, fostering self-growth and resilience.
        </TypographyBody> */}
        <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Excellence: </span>We are dedicated to providing exceptional care, guided by the latest evidence-based advancements in psychological science.
        </TypographyBody>
        <TypographyBody>
          <span style={{ fontWeight: 'bold' }}>Hope: </span>We believe in the power of the human spirit and the innate ability within each of us to heal, grow, and thrive.
        </TypographyBody>
        {/* <TypographyTitle>About Director</TypographyTitle>
        <TypographyBody>
          Ms. Yogeeta Kadian is Founder Director of Manosamvaad. She is a
          Psychologist, Clinical Hypnotherapist, Graphologist and Practitioner
          of CBT, DBT, REBT, New NLP Code, Positive Psychology and Transaction
          Analysis.
        </TypographyBody>
        <TypographyBody mt={2}>
          She has rich experience of working with individuals, groups, Sports
          Academy, De-addiction Centre. She is certified in Substance Addiction
          (Alcohol & other drugs) as well as Behaviour Addiction (gambling,
          social media, gaming, and internet addiction) from AIIMS. She did
          courses on Science of Happiness and Positive Psychology from Harvard
          University. She has conducted Offline and Online workshops in reputed
          schools, educational institutes and organizations across PAN India.
        </TypographyBody> */}
      </TextContainer>
      <ImageContainer>
        <InfScroll
          items={images}
          itemsOnPage={2}
          time={3}
          direction={'column'}
          renderItem={renderImages}
        />
      </ImageContainer>
    </MainContainer>
  );
};

export default About;
