import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { login } from '../utils/apiFunctions';
import serviceImage from '../images/contact.png';
import { setNotification } from '../store/notificationSlice';
import { styled } from '@mui/system';
import { submitForm } from '../utils/apiFunctions';
import { useState } from 'react';

const Background = styled('div')(({ theme }) => ({
  background: theme.palette.primary.background,
  minHeight: '89.7vh',
}));

const CenterDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '89.7vh',
  textAlign: 'center',
  margin: '0 1.25em',
});

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '6.25em',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    paddingTop: '6.5em',
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '27em',
  margin: '0 5em 1em 0',
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    margin: '0 1em 1em 1em',
  },
}));

const RowContainer = styled(Box)({
  display: 'flex',
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: '0.5em',
  '& .MuiInputBase-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.tertiary.background, // Border color on hover
    },
  },
  '& .Mui-focused': {
    '& fieldset': {
      borderColor: theme.palette.tertiary.background, // Border color when focused
    },
  },
  '& .MuiInputBase-input': {
    height: '1em',
    color: 'black',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #F8F1E5 inset',
      // WebkitTextFillColor: 'black',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '1em',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  textWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: '1em',
  letterSpacing: '0.02em',
  paddingLeft: '1em',
  paddingRight: '1em',
  margin: '0.5em 0em 0.5em 0.5em',
  textTransform: 'capitalize',
  borderColor: '#BDBDBD',
  '&.Mui-selected': {
    background: '#F49B01',
    color: 'white',
    '&:hover': {
      // background: theme.palette.secondary.main,
    },
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  width: '35em',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 1em',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '31em',
  margin: '0.5em 0 1em 0',
  [theme.breakpoints.down('sm')]: {
    width: '21em',
  },
}));

const Contact = () => {
  const dispatch = useDispatch();
  // const services = useSelector((state) => state.services.services);
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    email: '',
    phone: '',
    profession: '',
    issue: '',
    sessionWith: '',
  });
  const [phoneError, setPhoneError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const validatePhone = (value) => {
    if (!/^\d*$/.test(value)) {
      setPhoneError('Phone number should contain only digits.');
    } else if (value.length < 10) {
      setPhoneError('Phone number should be at least 10 digits long.');
    } else {
      setPhoneError('');
    }
  };

  const handleChange = (event, newVal) => {
    const { name, value } = event.target;
    if (name === 'phone') {
      validatePhone(value);
    }

    if (name === 'gender') {
      setFormData({ ...formData, gender: newVal });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!phoneError) {
      // If User doesn't exists, user can signup to view sessions
      try {
        await login({ email: formData.email, password: '' });
      } catch (err) {
        if (err.response.status === 400) {
          setUserExists(false);
        } else {
          setUserExists(true);
        }
      }

      try {
        // Submit Form
        const res = await submitForm(formData);
        if (res.status === 200) {
          setIsSubmitted(true);
          dispatch(
            setNotification({ message: res.data.message, type: 'success' })
          );
        }
      } catch (err) {
        console.error('Error submitting form', err);
        dispatch(
          setNotification({ message: 'Error submitting form!', type: 'error' })
        );
      }
    } else {
      // alert('Please fix the errors before submitting!');
    }
  };

  if (isSubmitted) {
    return (
      <Background>
        <CenterDiv>
          <Typography fontSize='1.25em' mb={3}>
            Thank You for sharing your details.
          </Typography>
          <Typography fontSize='1.25em'>
            We will reach out to you within 24 hours!
          </Typography>
          <Typography fontSize='1.25em'>
            In case you need help earlier, please connect with us.
          </Typography>
          {isLoggedIn ? (
            <Typography fontSize='1.25em' mt={3}>
              You can view your session details on your Profile.
            </Typography>
          ) : (
            <Typography fontSize='1.25em' mt={3}>
              You can view your session details by
              <Link
                to={userExists ? '../login' : '../signup'}
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  marginLeft: '0.25em',
                }}
              >
                {userExists ? 'Logging In!' : 'Signing up!'}
              </Link>
            </Typography>
          )}
        </CenterDiv>
      </Background>
    );
  }

  return (
    <Background>
      <MainContainer>
        <FormContainer component='form' onSubmit={handleSubmit}>
          <RowContainer>
            <StyledTextField
              label='Full Name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              variant='outlined'
              sx={{ width: '50%' }}
              required
            />
            <StyledTextField
              label='Age'
              name='age'
              value={formData.age}
              onChange={handleChange}
              variant='outlined'
              required
              sx={{ width: '20%' }}
            />
          </RowContainer>
          <FormControl component='fieldset'>
            <ToggleButtonGroup
              value={formData.gender}
              exclusive
              onChange={handleChange}
              aria-label='gender'
              name='gender'
            >
              <StyledToggleButton name='gender' value='Male' aria-label='male'>
                Male
              </StyledToggleButton>
              <StyledToggleButton
                name='gender'
                value='Female'
                aria-label='female'
              >
                Female
              </StyledToggleButton>
              {/* <StyledToggleButton
                  name='gender'
                  value='transgender'
                  aria-label='transgender'
                >
                  Transgender
                </StyledToggleButton> */}
              <StyledToggleButton
                name='gender'
                value='Other'
                aria-label='other'
              >
                Other
              </StyledToggleButton>
            </ToggleButtonGroup>
          </FormControl>
          <RowContainer>
            <StyledTextField
              label='Email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              variant='outlined'
              type='email'
              required
              sx={{ width: '80%' }}
            />
            <StyledTextField
              label='Phone'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              variant='outlined'
              required
              error={!!phoneError}
              helperText={phoneError}
            />
          </RowContainer>
          <StyledTextField
            label='Profession'
            name='profession'
            value={formData.profession}
            onChange={handleChange}
            variant='outlined'
            required
            sx={{ width: '55%' }}
          />
          <StyledTextField
            label='Briefly describe your issue (Your information is safe)'
            name='issue'
            value={formData.issue}
            onChange={handleChange}
            variant='outlined'
            multiline
            rows={3}
            required
          />
          <StyledTextField
            select
            label='Book 45mins session with'
            name='sessionWith'
            value={formData.sessionWith}
            onChange={handleChange}
            variant='outlined'
            required
            sx={{ width: { xs: '22.75em', sm: 'auto' }}}
            SelectProps={{
              MenuProps: {
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              },
            }}
          >
            <StyledMenuItem value='Director'>
              <strong>Director</strong>&nbsp;(Rs. 1800 - Individual | Rs. 2500 - Couple)
            </StyledMenuItem>
            <StyledMenuItem value='Counselling Psychologist'>
              <strong>Counselling Psychologist</strong>&nbsp;(Rs. 1200-1500 - Individual | Rs. 2000 - Couple)
            </StyledMenuItem>
            <StyledMenuItem value='Clinical Psychologist'>
              <strong>Clinical Psychologist</strong>&nbsp;(Rs. 1500 - Individual | Rs. 2000 - Couple)
            </StyledMenuItem>
            <StyledMenuItem value='Clinical Hypnotherapist'>
              <strong>Clinical Hypnotherapist</strong>&nbsp;(Rs. 2500 per hour)
            </StyledMenuItem>
            <StyledMenuItem value='Anyone'>
              <strong>Anyone depending upon the need</strong>
            </StyledMenuItem>
          </StyledTextField>
          <FormControlLabel
            sx={{ ml: -0.25 }}
            control={<Checkbox required />}
            label='I hereby give my consent for counselling with Manosamvaad'
          />
          <Button
            type='submit'
            variant='contained'
            sx={{
              mt: 1,
              ml: 1,
              width: '50%',
              fontSize: '0.9em',
            }}
          >
            Request Session
          </Button>
        </FormContainer>
        <TextContainer>
          <Typography
            fontSize='1.45em'
            color='primary'
            fontWeight='bold'
            mb={0.75}
            // textAlign='center'
          >
            Book your personal therapist!
          </Typography>
          <Typography fontSize='1.1em'>
            We'll match you with the perfect therapist in no time.
          </Typography>
          <Typography fontSize='1.1em' textAlign='center'>
            You deserve someone who specializes in your concern, understands you
            and fits your schedule.
          </Typography>
          <StyledImage src={serviceImage} alt='contact' />
        </TextContainer>
      </MainContainer>
    </Background>
  );
};

export default Contact;
