import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/system';

import useMediaQuery from '@mui/material/useMediaQuery';

const ScrollContainer = styled('div')(({ theme, direction }) => ({
  display: 'flex',
  margin: '1em',
  flexDirection: direction,
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    margin: 0,
  },
}));

const InfScroll = ({ items, itemsOnPage, time, direction, renderItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isMobile) itemsOnPage = 1;

  const [visibleItems, setVisibleItems] = useState(items.slice(0, itemsOnPage));
  const scrollRef = useRef(null);
  const timeoutRef = useRef(null);

  const startAutoScroll = () => {
    timeoutRef.current = setInterval(() => {
      setVisibleItems((prevItems) => {
        const nextIndex =
          (items.indexOf(prevItems[prevItems.length - 1]) + 1) % items.length;
        return [...prevItems.slice(1), items[nextIndex]];
      });
    }, time * 1000);
  };

  const stopAutoScroll = () => {
    clearInterval(timeoutRef.current);
  };

  useEffect(() => {
    if (visibleItems.length === 0) {
      setVisibleItems(items.slice(0, itemsOnPage));
    }
    startAutoScroll();
    return () => stopAutoScroll();
  }, [items, itemsOnPage]);

  return (
    <ScrollContainer
      direction={direction}
      onMouseEnter={stopAutoScroll}
      onMouseLeave={startAutoScroll}
      ref={scrollRef}
    >
      {visibleItems?.map((item, index) => (
        <div key={index} style={{ margin: '0.75em' }}>
          {item && renderItem(item)}
        </div>
      ))}
    </ScrollContainer>
  );
};

export default InfScroll;
