import authReducer from './authSlice';
import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from './notificationSlice';
import servicesReducer from './servicesSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    services: servicesReducer,
  },
});

export default store;
