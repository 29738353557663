import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { jwtDecode } from 'jwt-decode';
import { logoutUser } from '../store/authSlice';
import { setNotification } from '../store/notificationSlice';
import { useNavigate } from 'react-router-dom';

const autoLogout = (WrappedComponent) => {
  const AutoLogout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.authToken);

    useEffect(() => {
      try {
        if (token) {
          const decodedToken = jwtDecode(token);
          // expiry date - current date in seconds
          const expiry = decodedToken.exp - Date.now() / 1000;
          const timer = setTimeout(() => {
            dispatch(
              setNotification({ message: 'Logging out!', type: 'success' })
            );
            dispatch(logoutUser());
            navigate('/');
          }, expiry * 1000);
          return () => clearTimeout(timer);
        }
      } catch (err) {
        console.error('Error decoding token:', err);
      }
    }, [token]);

    return <WrappedComponent {...props} />;
  };
  return AutoLogout;
};

export default autoLogout;
