import { Email, PhotoCamera } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  fetchUserData,
  fetchUserSessions,
  fetchUsers,
  sendEmail,
  updateSession,
  uploadProfileImage,
} from '../utils/apiFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete } from '@mui/material';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import defaultImage from '../images/defaultProfileImg.png';
import { setNotification } from '../store/notificationSlice';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import withAuth from '../HOC/authProtectedRoute';

const Background = styled('div')(({ theme }) => ({
  background: theme.palette.primary.background,
  height: '89.7vh',
  [theme.breakpoints.down('sm')]: {
    height: '85.09vh',
  },
}));

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '6em',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '83vw',
  maxHeight: '24em',
  background: '#F6EEDF',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  textAlign: 'center',
  padding: '10px 12px',
}));

const UserDetails = styled('div')({
  alignSelf: 'center',
  marginLeft: '0.5em',
});
const UploadImage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '-0.6em',
});

const IssueTypography = styled(Typography)({
  fontSize: '1em',
  minWidth: '20em',
  maxHeight: '4.5em',
  overflowY: 'auto',
});

const Profile = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [therapists, setTherapists] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [userSessions, setUserSessions] = useState([]);
  const [sessionsMessage, setSessionsMessage] = useState('');
  const authToken = useSelector((state) => state.auth.authToken);
  const params = useParams();
  const userId = params.userId;

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const res = await fetchUserData({ authToken, userId });
        setUserData(res);
        if (res.image) {
          setProfileImage(res.image);
        }

        const sessions = await fetchUserSessions({
          authToken,
          email: res.email,
          role: res.role,
        });
        setUserSessions(sessions.sessions);
        setSessionsMessage(sessions.message);

        if (res.role === 'admin') {
          const fetchRole = 'therapist';
          const therapists = await fetchUsers({ fetchRole }); // fetches all therapists
          setTherapists(therapists);
        }
      } catch (err) {
        dispatch(
          setNotification({
            message: err.response.data.error,
            type: 'error',
          })
        );
      }
    };

    if (authToken && userId) {
      loadUserData();
    }
  }, [authToken, userId, dispatch]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const firstName = userData.firstName.replace(/^Dr\.\s*/, '').trim();
      const filename = `${firstName}_${userData.lastName.trim()}_image${file.name.slice(file.name.lastIndexOf('.'))}`;
      const renamedFile = new File([file], filename, {
        type: file.type,
      });
      const formData = new FormData();
      formData.append('image', renamedFile);
      formData.append('email', userData.email);
      
      try {
        const res = await uploadProfileImage({ authToken, formData });
        const imageUrl = `${res.image}?v=${new Date().getTime()}`;
        setProfileImage(imageUrl);
        dispatch(setNotification({ message: res.message, type: 'success' }));
      } catch (err) {
        dispatch(
          setNotification({
            message: err.response.data.error,
            type: 'error',
          })
        );
      }
    }
  };

  const handleTimeChange = async (newValue, sessionId) => {
    const dateTime = new Date(newValue);
    const updatedSessions = userSessions.map((session) =>
      session._id === sessionId ? { ...session, time: dateTime } : session
    );
    setUserSessions(updatedSessions);

    const attribute = {
      'time': dateTime,
    };
    const res = await updateSession({ authToken, sessionId, attribute });
    dispatch(setNotification({ message: res.message, type: 'success' }));
  };

  const handleTherapistChange = async (event, newValue, sessionId) => {
    const updatedSessions = userSessions.map((session) =>
      session._id === sessionId ? { ...session, therapist: newValue } : session
    );
    setUserSessions(updatedSessions);

    const attribute = {
      'therapist': newValue,
    };
    const res = await updateSession({ authToken, sessionId, attribute });
    dispatch(setNotification({ message: res.message, type: 'success' }));
  };

  const handleLinkChange = async (event, sessionId) => {
    const value = event.target.value;
    const updatedSessions = userSessions.map((session) =>
      session._id === sessionId ? { ...session, url: value } : session
    );
    setUserSessions(updatedSessions);

    const attribute = {
      'url': value,
    };
    const res = await updateSession({ authToken, sessionId, attribute });
    dispatch(setNotification({ message: res.message, type: 'success' }));
  };

  const handleSendEmail = async (
    clientName,
    clientAge,
    clientGender,
    clientProfession,
    clientEmail,
    clientPhone,
    sessionId
  ) => {
    const session = userSessions.find((session) => session._id === sessionId);
    const sessionDate = dayjs(session.time).format('DD MMMM, YYYY');
    const sessionStartTime = dayjs(session.time).format('hh:mm a');
    const sessionEndTime = dayjs(session.time)
      .add(45, 'minute')
      .format('hh:mm a');

    const sessionData = {
      clientName: clientName,
      clientAge: clientAge,
      clientGender: clientGender,
      clientProfession: clientProfession,
      clientEmail: clientEmail,
      clientPhone: clientPhone,
      sessionDate: sessionDate,
      sessionStartTime: sessionStartTime,
      sessionEndTime: sessionEndTime,
      therapist: session.therapist,
      sessionUrl: session.url,
    };
    try {
      const res = await sendEmail({ authToken, sessionData });
      dispatch(setNotification({ message: res.message, type: 'success' }));
    } catch (err) {
      console.log(err.response);
      dispatch(
        setNotification({
          message: err.response.data.error,
          type: 'error',
        })
      );
    }
  };

  return (
    <Background>
      <MainContainer>
        <div style={{ display: 'flex' }}>
          <img
            src={profileImage}
            width='150em'
            height='150em'
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            alt='profile-img'
          />
          <UserDetails>
            <Typography variant='h6' mt={1}>
              {userData.firstName}
              &nbsp;
              {userData.lastName}
            </Typography>
            <Typography>{userData.email}</Typography>
            {/* userData.role */}
            <UploadImage>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='image-upload'
                name='image'
                type='file'
                onChange={handleImageUpload}
              />
              <label htmlFor='image-upload'>
                <IconButton color='primary' component='span'>
                  <PhotoCamera />
                </IconButton>
              </label>
              <Typography color='primary'>Upload picture</Typography>
            </UploadImage>
          </UserDetails>
        </div>
        <Typography fontWeight='bold' mt={0.5}>
          {sessionsMessage}
        </Typography>
        {userSessions?.length > 0 && (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {(userData.role === 'user' || userData.role === 'admin') && (
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      Status
                    </StyledTableCell>
                  )}
                  {(userData.role === 'therapist' ||
                    userData.role === 'admin') && (
                    <>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        Client
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        Profession
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        Contact
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        Issue
                      </StyledTableCell>
                    </>
                  )}
                  {userData.role === 'admin' && (
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      Request
                    </StyledTableCell>
                  )}
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                    Date & Time
                  </StyledTableCell>
                  {(userData.role === 'user' || userData.role === 'admin') && (
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      Therapist
                    </StyledTableCell>
                  )}
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                    Meeting Link / Location
                  </StyledTableCell>
                  {(userData.role === 'therapist' ||
                    userData.role === 'admin') && (
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      Email
                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {userSessions.map((data) => (
                  <TableRow key={data._id}>
                    {(userData.role === 'user' ||
                      userData.role === 'admin') && (
                      <StyledTableCell sx={{ minWidth: '6em' }}>
                        {data.status}
                      </StyledTableCell>
                    )}
                    {(userData.role === 'therapist' ||
                      userData.role === 'admin') && (
                      <>
                        <StyledTableCell sx={{ minWidth: '8em' }}>
                          {data.clientName}
                          <div>
                            {data.clientAge}, {data.clientGender}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.clientProfession}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.clientEmail}, {data.clientPhone}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IssueTypography>{data.clientIssue}</IssueTypography>
                        </StyledTableCell>
                      </>
                    )}
                    {userData.role === 'admin' && (
                      <StyledTableCell sx={{ minWidth: '12em' }}>
                        {data.sessionWith}
                      </StyledTableCell>
                    )}
                    {userData.role === 'therapist' ||
                    userData.role === 'admin' ? (
                      <StyledTableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDateTimePicker
                            label='Select Date/Time'
                            value={dayjs(data.time)}
                            format='DD MMMM YYYY, hh:mm a'
                            onAccept={(newValue) =>
                              handleTimeChange(newValue, data._id)
                            }
                            textField={(params) => <TextField {...params} />}
                            sx={{ minWidth: '17.5em' }}
                          />
                        </LocalizationProvider>
                      </StyledTableCell>
                    ) : data.time ? (
                      <StyledTableCell sx={{ minWidth: '15.5em' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimeField
                            value={dayjs(data.time)}
                            format='DD MMMM YYYY, hh:mm a'
                            readOnly
                          />
                        </LocalizationProvider>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>Not Assigned</StyledTableCell>
                    )}
                    {userData.role === 'user' && (
                      <StyledTableCell>
                        {data.therapist
                          ? `${data.therapist.firstName} ${data.therapist.lastName}`
                          : 'Not Assigned'}
                      </StyledTableCell>
                    )}
                    {userData.role === 'admin' && (
                      <StyledTableCell>
                        <Autocomplete
                          options={therapists}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          defaultValue={data.therapist}
                          onChange={(event, newValue) =>
                            handleTherapistChange(event, newValue, data._id)
                          }
                          renderInput={(params) => (
                            <TextField {...params} label='Therapist' />
                          )}
                          sx={{ minWidth: '14em' }}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell sx={{ minWidth: '12em' }}>
                      {userData.role === 'therapist' ||
                      userData.role === 'admin' ? (
                        <TextField
                          label='Enter Link/ Location'
                          variant='outlined'
                          defaultValue={data.url}
                          onChange={(event) =>
                            handleLinkChange(event, data._id)
                          }
                        />
                      ) : data.url ? (
                        <a
                          href={data.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {data.url}
                        </a>
                      ) : (
                        'Not Assigned'
                      )}
                    </StyledTableCell>
                    {(userData.role === 'therapist' ||
                      userData.role === 'admin') && (
                      <StyledTableCell>
                        <IconButton
                          color='primary'
                          onClick={() =>
                            handleSendEmail(
                              data.clientName,
                              data.clientAge,
                              data.clientGender,
                              data.clientProfession,
                              data.clientEmail,
                              data.clientPhone,
                              data._id
                            )
                          }
                        >
                          <Email />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </MainContainer>
    </Background>
  );
};

export default withAuth(Profile);
