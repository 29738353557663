import {
  Email,
  FacebookRounded,
  Instagram,
  LocationOn,
  Phone,
  WhatsApp,
  YouTube,
} from '@mui/icons-material';

import { FOOTER_URLS } from '../constants/constants';
import LogoImg from '../images/logo.jpg';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const CopyrightContainer = styled('div')({
  display: 'flex',
});
const ContactContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const LogoImage = styled('img')({
  width: '3.25em',
  padding: '0.75em',
  borderRadius: '15px',
});

const Copyright = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const IconsContainer = styled('div')({
  width: '16em',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '1.2em',
  paddingTop: '0.25em',
});

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.background,
}));

const IconsLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.background,
  '&:hover': { color: '#FFF0D7' },
}));

const Footer = () => {
  return (
    <FooterContainer>
      <CopyrightContainer>
        <LogoImage src={LogoImg} />
        <Copyright>
          <FooterText fontSize='1.3em'>Manosamvaad</FooterText>
          <FooterText fontSize='0.9em'>
            &copy; 2024. All Rights Reserved
          </FooterText>
        </Copyright>
      </CopyrightContainer>
      <ContactContainer>
        <FooterText fontSize='1.2em'>Connect with us</FooterText>
        <IconsContainer>
          <IconsLink
            href={FOOTER_URLS.MAP_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LocationOn />
          </IconsLink>
          <IconsLink href={`mailto:${FOOTER_URLS.EMAIL}`}>
            <Email />
          </IconsLink>
          <IconsLink href={`tel:${FOOTER_URLS.PHONE}`}>
            <Phone />
          </IconsLink>
          <IconsLink
            href={FOOTER_URLS.WHATSAPP_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <WhatsApp />
          </IconsLink>
          <IconsLink
            href={FOOTER_URLS.FACEBOOK_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <FacebookRounded />
          </IconsLink>
          <IconsLink
            href={FOOTER_URLS.INSTAGRAM_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Instagram />
          </IconsLink>
          <IconsLink
            href={FOOTER_URLS.YOUTUBE_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <YouTube />
          </IconsLink>
        </IconsContainer>
      </ContactContainer>
    </FooterContainer>
  );
};

export default Footer;
