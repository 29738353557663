import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const token = useSelector((state) => state.auth.isAuthenticated);
    if (!token) {
      return <Navigate to='/login' />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
