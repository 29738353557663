import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/system';

const ButtonContainer = styled('div')(({ theme, disabled }) => ({
  width: '3em',
  height: '3em',
  background: theme.palette.tertiary.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.5 : 1,
}));

const NextButton = styled(NavigateNextIcon)(({ direction }) => ({
  color: '#FFF',
  fontSize: '2.25em',
  transform: direction == 'left' ? 'scaleX(-1)' : 'scaleX(1)',
}));

const CircularButton = ({ direction, onClickHandler, disabled, marginTop }) => {
  return (
    <ButtonContainer
      onClick={onClickHandler}
      disabled={disabled}
      style={{ marginTop: marginTop }}
    >
      <NextButton direction={direction} />
    </ButtonContainer>
  );
};
export default CircularButton;
