import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authToken: localStorage.getItem('authToken'),
  userId: localStorage.getItem('userId'),
  isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginUser: (state, action) => {
      const authToken = action.payload.authToken;
      const userId = action.payload.userId;
      state.authToken = authToken;
      state.userId = userId;
      state.isAuthenticated = true;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('userId', userId);
      localStorage.setItem('isAuthenticated', true);
    },
    logoutUser: (state, action) => {
      state.authToken = null;
      state.userId = null;
      state.isAuthenticated = false;
      localStorage.removeItem('authToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('isAuthenticated');
    },
  },
});

export const { loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
