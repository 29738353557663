import { ThemeProvider, createTheme } from '@mui/material/styles';

import { GlobalStyles } from '@mui/material';
import React from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E4572E', // orange
      background: '#F8F1E5', // skin
    },
    secondary: {
      main: '#397A4A', // green
      // background: '#C8E5F9', // lightest blue
      background: '#DDF2D1', // light green || #C4E9C4
    },
    tertiary: {
      main: '#3D3D43', // light grey
      background: '#FEBB48', // dark yellow
    },
  },
  typography: {
    fontFamily: 'Source Sans, sans-serif',
  },
});

const ThemeWrapper = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: {
            fontFamily: theme.typography.fontFamily,
            background: '#FFF',
          },
        }}
      />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
