import { Close, Menu } from '@mui/icons-material';

import styled from '@emotion/styled';

const MenuIcon = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    cursor: 'pointer',
  },
}));

const MenuMobile = ({ isMenuOpen, toggleMenu }) => {
  return (
    <MenuIcon>
      {!isMenuOpen && <Menu onClick={() => toggleMenu(true)} />}
      {isMenuOpen && <Close onClick={() => toggleMenu(false)} />}
    </MenuIcon>
  );
};

export default MenuMobile;
